<nav id="sidebar" [ngClass]="['sidebar', isCollapsed() ? 'sidebar-collapsed' : 'sidebar-opened']"
     *ngIf="!isFinsteinHelper()">
    <div>
        <div class="company-info mt-4">
            <div class="row text-center margin-user">
                <div class="col">
                    <span class="label-user">Finstein GmbH</span>
                </div>
            </div>
        </div>

        <div class="mt-6" id="container-item">
            <ul class="list-unstyled">
                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.companyLeads"
                    [ngClass]="{active: isActiveRoute('/administration/company-leads/**')}" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/company-leads']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">business</i>
                        </span>
                        <span class="menu-title" translate>
                            company-leads.title.plural
                        </span>
                    </a>
                </li>

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.users"
                    [ngClass]="{active: isActiveRoute('/administration/users')}">
                    <a class="nav-link" [routerLink]="['/administration/users']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">badge</i>
                        </span>
                        <span class="menu-title" translate>
                            users.title.plural
                        </span>
                    </a>
                </li>

                <!-- Disabled -->
<!--                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"-->
<!--                    [ngClass]="{active: isActiveRoute('/administration/roles')}" routerLinkActive="active">-->
<!--                    <a class="nav-link" [routerLink]="['/administration/roles']">-->
<!--                        <span class="avatar mr-3 align-self-center avatar-md">-->
<!--                            <i class="material-icons">assignment</i>-->
<!--                        </span>-->
<!--                        <span class="menu-title" translate>-->
<!--                            roles.title.plural-->
<!--                        </span>-->
<!--                    </a>-->
<!--                </li>-->

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"
                    [ngClass]="{active: isActiveRoute('/administration/employees')}">
                    <a class="nav-link" [routerLink]="['/administration/employees']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">person</i>
                        </span>
                        <span class="menu-title" translate>
                            employees.title
                        </span>
                    </a>
                </li>
                <li class="menu-item nav-item nav-subitem"
                    *ngxPermissionsOnly="permissions.roles"
                    routerLink="/administration/receipts"
                    [ngClass]="{
                        'd-none': isCollapsed(),
                        active: isActiveRoute('/administration/receipts') ||
                         isActiveRoute('/administration/employees/**/receipts/**/review', '^\/administration\/employees\/.*\/receipts\/.*\/review$')
                    }">
                    <a class="without-icon" translate>
                        review-receipt.title
                    </a>
                </li>

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"
                    [ngClass]="{active: isActiveRoute('/administration/contracts-tasks')}" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/contracts-tasks']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">rule</i>
                        </span>
                        <span class="menu-title" translate>
                            contracts-tasks.title
                        </span>
                    </a>
                </li>

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles">
                    <a class="nav-link">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">phone_iphone</i>
                        </span>
                        <span class="menu-title" translate>
                            smartphone.side-menu
                        </span>
                    </a>
                </li>
                <li class="menu-item nav-item nav-subitem" *ngxPermissionsOnly="permissions.roles"
                    routerLink="/administration/smartphone"
                    routerLinkActive="active"
                    [ngClass]="{'d-none': isCollapsed()}"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="without-icon" translate>
                        smartphone.title
                    </a>
                </li>

<!--                <li class="menu-item nav-item nav-subitem"-->
<!--                    routerLinkActive="active"-->
<!--                    routerLink="/administration/smartphone/image"-->
<!--                    [ngClass]="{'d-none': isCollapsed()}"-->
<!--                    [routerLinkActiveOptions]="{exact: true}">-->
<!--                    <a class="without-icon" translate>-->
<!--                        smartphone.smartphone-image.title-->
<!--                    </a>-->
<!--                </li>-->

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"
                    [ngClass]="{active: isActiveRoute('/administration/support-requests')}" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/support-requests']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">support_agent</i>
                        </span>
                        <span class="menu-title" translate>
                            support-requests.title
                        </span>
                    </a>
                </li>

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"
                    [ngClass]="{active: isActiveRoute('/administration/emails-leads')}" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/emails-leads']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">email</i>
                        </span>
                        <span class="menu-title" translate>
                            emails-leads.title
                        </span>
                    </a>
                </li>

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"
                    [ngClass]="{active: isActiveRoute('/administration/app-notifications')}" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/app-notifications']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">notification_add</i>
                        </span>
                        <span class="menu-title" translate>
                            optimizer-notifications.title
                        </span>
                    </a>
                </li>

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"
                    [ngClass]="{active: isActiveRoute('/administration/download-center')}" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/download-center']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">download</i>
                        </span>
                        <span class="menu-title" translate>
                            download-center.title
                        </span>
                    </a>
                </li>

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"
                    [ngClass]="{active: isActiveRoute('/administration/partners')}" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/partners']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">receipt</i>
                        </span>
                        <span class="menu-title" translate>
                            partner-companies.title
                        </span>
                    </a>
                </li>

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"
                    [ngClass]="{active: isActiveRoute('/administration/cell-phone-management')}"
                    routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/cell-phone-management']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">cell_wifi</i>
                        </span>
                        <span class="menu-title" translate>
                            cell-phone-management.title
                        </span>
                    </a>
                </li>

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"
                    [ngClass]="{active: isActiveRoute('/administration/settings/email-template')}"
                    routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/settings/email-template']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">mail</i>
                        </span>
                        <span class="menu-title" translate>
                            settings.email-template.title
                        </span>
                    </a>
                </li>

                <!-- Disabled until we are able to update the form to suit all scenarios
                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"
                    [ngClass]="{active: isActiveRoute('/administration/services')}" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/services']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">new_label</i>
                        </span>
                        <span class="menu-title" translate>
                            service.title
                        </span>
                    </a>
                </li>

                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles"
                    [ngClass]="{active: isActiveRoute('/administration/variables')}" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/variables']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">app_registration</i>
                        </span>
                        <span class="menu-title" translate>
                            global-variables.title
                        </span>
                    </a>
                </li>
                <li class="menu-item nav-item" *ngxPermissionsOnly="permissions.roles" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/administration/bookkeeping-key']">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">receipt_long</i>
                        </span>
                        <span class="menu-title" translate>
                            bookkeeping-keys.title.plural
                        </span>
                    </a>
                </li>
                -->

                <li class="menu-item nav-item btn-close" (click)="toggleSidebar()">
                    <a class="nav-link">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">menu_open</i>
                        </span>
                        <span class="menu-title" translate>
                            core.side-menu.collapse-menu
                        </span>
                    </a>
                </li>
            </ul>
            <app-version-tag class="home-version-tag"></app-version-tag>
        </div>
    </div>
</nav>
