import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NotificationDetailsComponent} from './notification-details.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [NotificationDetailsComponent],
    exports: [NotificationDetailsComponent]
})
export class NotificationDetailsModule {
}
