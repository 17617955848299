import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../core/abstractions/base.component';

@Component({
    selector: 'app-notification-bell',
    templateUrl: './notification-bell.component.html',
    styleUrls: ['./notification-bell.component.scss']
})
export class NotificationBellComponent extends BaseComponent {

    @Input() number: number;

    constructor() {
        super();
    }
}
