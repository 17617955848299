import { Component, OnInit } from '@angular/core';
import { InactiveUserService } from 'src/app/core/components/inactive-user/inactive-user.service';
import { SideMenuService } from 'src/app/core/services/side-menu.service';
import { UserService } from 'src/app/core/services/user.service';
import { NotificationService } from '../../../core/services/notification.service';
import { AuthFirebaseService } from '../../cross-profile/authentication/auth-firebase.service';

@Component({
    selector: 'app-main-company',
    templateUrl: './main-company.component.html',
    styleUrls: ['./main-company.component.scss'],
})
export class MainCompanyComponent implements OnInit {
    constructor(
        private sideMenuService: SideMenuService,
        private notification: NotificationService,
        private inactiveUserService: InactiveUserService,
        private auth: AuthFirebaseService,
        private userService: UserService,
    ) {}

    ngOnInit() {
        this.notification.requestPermission();
        this.auth.setUserSession(this.userService.loggedUser.id).subscribe(() => {
            this.auth.listenUserSessionChanged(this.userService.loggedUser.id);
        });
        this.inactiveUserService.startInactiveUserSchedule();
    }

    isCollapsed() {
        return this.sideMenuService.opened;
    }
}
