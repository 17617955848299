import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './system-warnings-notification.component.html',
    styleUrls: ['./system-warnings-notification.component.scss'],
})
export class SystemWarningsNotificationComponent {
    constructor(
        private dialogRef: MatDialogRef<SystemWarningsNotificationComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            description: string;
            withRefresh: boolean;
            afterConfirmFn: () => {};
        }
    ) {}

    close() {
        this.dialogRef.close();
    }

    confirm() {
        this.data.afterConfirmFn();
        this.dialogRef.close();
    }
}
