import { CurrencyMaskConfig } from 'ng2-currency-mask';

export const CurrencyConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    decimal: ',',
    precision: 2,
    prefix: '',
    suffix: ' €',
    thousands: '.'
};
