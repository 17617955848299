import {Component, Input, OnInit} from '@angular/core';
import moment from 'moment';

declare let $: any;

@Component({
    selector: 'app-notification-details',
    templateUrl: './notification-details.component.html',
    styleUrls: ['./notification-details.component.scss']
})
export class NotificationDetailsComponent implements OnInit {

    @Input() target: string;
    @Input() notification: any;
    @Input() public isSecondLevelModal = false;

    constructor() {
    }

    ngOnInit(): void {
        if (this.isSecondLevelModal) {
            $(document).on('hidden.bs.modal', '.modal', () => {
                $('body').addClass('modal-open');
            });
        }
    }

    getNotificationTime() {
        if (this.notification && this.notification.createdAt) {
            return moment(this.notification.createdAt.toDate()).fromNow();
        }
    }

}
