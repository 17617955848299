<div class="header-container">
    <div class="header">

        <div class="user-info">
            <div class="user-image">
                <img alt="User" src="assets/images/default-user.svg">
            </div>

            <div ngbDropdown placement="bottom-right">
                <div id="settings-dropdown" class="cursor-pointer d-flex" ngbDropdownToggle>
                    <span class="user-name">{{loggedUser?.firstName}} {{loggedUser?.lastName}}</span>
                </div>
                <div ngbDropdownMenu aria-labelledby="settings-dropdown" class="mt-2">
                    <div class="row padding mb-5">
                        <span appEllipsify class="language-label">{{this.loggedUser.email}}</span>
                    </div>
                    <div class="row padding d-flex align-items-center" *ngIf="isAllowedEnv()">
                        <span class="language-label" translate>system.language</span>
                        <select class="form-control background-select"
                                id="langKey"
                                name="langKey"
                                (change)="changeLanguage()"
                                [(ngModel)]="language"
                                [ngModelOptions]="{standalone: true}">

                            <option *ngFor="let language of languages"
                                    [ngValue]="language">{{language | findLanguageFromKey}}</option>
                        </select>
                    </div>

                    <hr/>

                    <!--------------------- SETTINGS ---------------------->
                    <div class="settings cursor-default" *ngIf="!loggedUser.finsteinUser &&
                    hasAnyPermission('deliver-employee-master-data-to-finstein')">

                        <a class="d-flex justify-content-start align-items-center p-2" matRipple
                            aria-expanded="false" href="#settingsSecondLevel" data-toggle="collapse">
                            <i class="material-icons icon">settings</i>
                            <span class="ml-2 flex-grow-1 pt-0" translate>system.settings</span>
                            <i class="ml-2 mr-2 material-icons-outlined">expand_more</i>
                        </a>

                        <div class="collapse" id="settingsSecondLevel" data-parent="#sidebar">
                            <!--
                                TODO: Temporarily disabled
                                <div class="border-bottom"  matRipple>
                                    <div class="ml-4 p-1 pt-2 pb-2"
                                        [ngClass]="{disabled: isPendentCompany()}"
                                        [routerLink]="['/settings/notifications']">
                                        <a translate>settings.notifications.title</a>
                                    </div>
                                </div>
                            -->
                            <div class="border-bottom mb-2"
                                 *ngIf="hasAnyPermission('deliver-employee-master-data-to-finstein')" matRipple>
                                <div class="ml-4 p-1 pt-2 pb-2"
                                [ngClass]="{disabled: isPendentCompany()}"
                                [routerLink]="['/settings/optins-import']">
                                    <a translate>settings.optins-importation.title</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--------------------- Update user info ---------------------->
                    <div class="d-flex justify-content-start align-items-center p-2 cursor-pointer" (click)="openDialogChangePassword()" matRipple>
                        <i class="material-icons icon" style="margin-left: 2px">vpn_key</i>
                        <span class="ml-2 pt-0" translate>authentication.change-password.title</span>
                    </div>

                    <!--------------------- LOGOUT ---------------------->
                    <div class="d-flex justify-content-start align-items-center p-2 cursor-pointer"
                         (click)="logout()" matRipple>
                        <i class="material-icons icon" style="margin-left: 2px">logout</i>
                        <span class="ml-2 pt-0" translate>system.logout</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-notification-details target="modal-notifications-details"
                          [notification]="selectedNotification"></app-notification-details>
