/* src/app/core/components/notification-details/notification-details.component.scss */
.notification-title {
  font-size: 17px;
}
.notification-time {
  text-align: end;
  font-size: 12px;
  color: grey;
  padding-right: 20px;
}
/*# sourceMappingURL=notification-details.component-DDRIVEZK.css.map */
