<nav id="sidebar" [ngClass]="['sidebar', isCollapsed() ? 'sidebar-collapsed' : 'sidebar-opened']">
    <div>
        <div class="company-info mt-4">
            <div class="row text-center margin-user justify-content-center">
                <div class="col-12">
                    <h1 class="label-user">
                        {{ loggedUser?.company?.name }}
                    </h1>
                </div>
                <span *ngIf="loggedUser?.company?.isDemo"
                      class="badge badge-primary"
                      [matTooltip]="'demo.description' | translate"
                      matTooltipClass="text-center"
                      translate
                    >
                    demo.title
                </span>
            </div>
        </div>

        <div class="mt-6" id="container-item">
            <ul class="list-unstyled">

                <!--------------------- COCKPIT ---------------------->
                <li class="menu-item nav-item"
                    routerLink="/cockpit"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                    (click)="activeSidebar()"
                >
                    <a>
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <img class="side-menu-icon" src="assets/icons/cockpit.svg" alt="Cockpit"/>
                        </span>
                        <span class="menu-title" translate>
                            core.side-menu.cockpit
                        </span>
                        <app-notification-bell
                            *ngIf="qtyTasksToDo > 0 && !this.isCollapsed()"
                            class="notification-bell-container"
                            [number]="qtyTasksToDo"
                        >
                        </app-notification-bell>
                    </a>
                </li>

                <li class="menu-item nav-item"
                    *ngxPermissionsOnly="permissions.companyMasterData"
                    [routerLink]="['/cockpit/company-master-data/base-data', this.getCompanyId()]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a>
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">business</i>
                        </span>
                        <span class="menu-title" translate>core.side-menu.company-master-data</span>
                    </a>
                </li>

                <li class="menu-item nav-subitem"
                    *ngxPermissionsOnly="permissions.services"
                    [ngClass]="{ disabled: isPendentCompany(), 'd-none': isCollapsed() }"
                    routerLink="/cockpit/company-master-data/services"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a class="without-icon" translate>
                        service.title
                    </a>
                </li>

                <li class="menu-item nav-item"
                    *ngxPermissionsOnly="permissions.actionSequence"
                    routerLink="/cockpit/action-sequences"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a>
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">playlist_add</i>
                        </span>
                        <span class="menu-title" translate>core.side-menu.manage-action-sequences</span>
                    </a>
                </li>

                <div *ngIf="checkOnlyAdminPermission(true)">
                    <li class="menu-item nav-item"
                        *ngxPermissionsOnly="permissions.actionSequence"
                        routerLink="/cockpit/rights"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                    >
                        <a>
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">visibility</i>
                        </span>
                            <span class="menu-title" translate>core.side-menu.rights</span>
                        </a>
                    </li>
                </div>

                <!--------------------- BOOKING SUGGESTIONS ---------------------->

                <li class="menu-item nav-item menu-item-no-hover"
                    *ngxPermissionsOnly="permissions.payrollBookkeeping"
                    [ngClass]="{disabled: isPendentCompany()}"
                    routerLink="/bookkeeping-suggestions/payroll-bookkeeping"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a href="#bookingSecondLevel" data-toggle="collapse" aria-expanded="false">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <img class="side-menu-icon" src="assets/icons/bookkeeping-suggestions.svg"
                                 alt="Bookkeeping suggestions"/>
                        </span>
                        <span class="menu-title" translate>core.side-menu.payroll-bookkeeping</span>
                    </a>
                </li>

                <li class="menu-item nav-item nav-subitem"
                    *ngxPermissionsOnly="permissions.bookkeepingSuggestions"
                    [ngClass]="{disabled: isPendentCompany(), 'd-none': isCollapsed()}"
                    routerLink="/bookkeeping-suggestions/list"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a class="without-icon" translate>
                        core.side-menu.bookkeeping-suggestions
                    </a>
                </li>

                <li class="menu-item nav-item nav-subitem"
                    *ngxPermissionsOnly="permissions.bookkeepingKeys"
                    [ngClass]="{disabled: isPendentCompany(), 'd-none': isCollapsed()}"
                    routerLinkActive="active"
                    routerLink="/bookkeeping-suggestions/bookkeeping-keys"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a class="without-icon" translate>
                        core.side-menu.bookkeeping-keys
                    </a>
                </li>

                <!--------------------- USERS ---------------------->

                <li class="menu-item nav-item" *ngIf="checkOnlyAdminPermission(true)"
                    [ngClass]="{ disabled: isPendentCompany()}"
                    routerLink="/users"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a class="nav-link">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">badge</i>
                        </span>
                        <span class="menu-title" translate>users.title.plural</span>
                    </a>
                </li>

                <!--------------------- EMPLOYEES ---------------------->

                <li class="menu-item nav-item"
                    *ngxPermissionsOnly="permissions.employees"
                    [ngClass]="{disabled: isPendentCompany()}"
                    routerLink="/employees"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a class="nav-link">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">person</i>
                        </span>
                        <span class="menu-title" translate>employees.title</span>
                    </a>
                </li>

                <!--------------------- OPTINS ---------------------->

                <li class="menu-item nav-item"
                    *ngxPermissionsOnly="permissions.optins"
                    [ngClass]="{disabled: isPendentCompany()}"
                    routerLink="/optins"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a class="nav-link">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">how_to_reg</i>
                        </span>
                        <span class="menu-title" translate>optins.title.plural</span>
                    </a>
                </li>

                <!--------------------- APPROVALS HISTORY ---------------------->

                <li class="menu-item nav-item"
                    routerLink="/approvals-history"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a class="nav-link">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">rule</i>
                        </span>
                        <span class="menu-title" translate>approvals-history.title</span>
                    </a>
                </li>

                <!--------------------- HARDWARE ---------------------->

                <li class="menu-item nav-item"
                    *ngxPermissionsOnly="permissions.hardware"
                    routerLink="/hardware"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a class="nav-link">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">devices_other</i>
                        </span>
                        <span class="menu-title" translate>hardware.title</span>
                    </a>
                </li>

                <!--------------------- VIDEO LIBRARY ---------------------->

                <li class="menu-item nav-item"
                    routerLink="/video-library"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    <a class="nav-link">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">video_library</i>
                        </span>
                        <span class="menu-title" translate>video-library.title</span>
                    </a>
                </li>

                <!------------------- SUPPORT APPOINTMENT -------------------->

                <li class="menu-item nav-item" *ngIf="enableScheduleSupport()">
                    <a class="nav-link" [href]="getScheduleSupportAppointmentLink()" target="_blank">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons">contact_support</i>
                        </span>
                        <span class="menu-title" translate>book-a-support-appointment.title</span>
                    </a>
                </li>

                <!--------------------- TOGGLE MENU ---------------------->

                <li class="menu-item nav-item btn-close" (click)="toggleSidebar()">
                    <a class="nav-link">
                        <span class="avatar mr-3 align-self-center avatar-md">
                            <i class="material-icons mt-1">{{isCollapsed() ? 'navigate_next' : 'menu_open'}}</i>
                        </span>
                        <span class="menu-title" translate>core.side-menu.collapse-menu</span>
                    </a>
                </li>

            </ul>
            <div class="home-version-tag">
                <app-version-tag class="row"></app-version-tag>
            </div>
        </div>
    </div>
</nav>
