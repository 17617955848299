import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { RouterModule } from '@angular/router';

import { SystemWarningsService } from './system-warnings.service';
import { BaseModalModule } from '../base-modal/base-modal.module';
import { SystemWarningsNotificationComponent } from './system-warnings-notification/system-warnings-notification.component';

@NgModule({
    declarations: [SystemWarningsNotificationComponent],
    imports: [
        CommonModule,
        AngularFireDatabaseModule,
        BaseModalModule,
        RouterModule,
    ],
    providers: [SystemWarningsService]
})
export class SystemWarningsModule {}
