/* src/app/shared/components/notification-bell/notification-bell.component.scss */
.custom-badge {
  position: relative;
  left: 28px !important;
  font-size: 74% !important;
  bottom: 13px !important;
  border-radius: 50% !important;
  color: white !important;
  top: -17px;
  width: 21px;
  height: 18px;
  padding-top: 0;
  padding-left: 4px !important;
  z-index: 1;
}
/*# sourceMappingURL=notification-bell.component-YTBNLTUP.css.map */
