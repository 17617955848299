import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainCompanyComponent } from './modules/company/main/main-company.component';
import { canActivate } from './modules/cross-profile/authentication/auth-guard.service';
import { SettingsComponent } from './modules/cross-profile/settings/settings.component';
import { MainAdministrationComponent } from './modules/administration/main/main-administration.component';
import { TranslationResolver } from './translation.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [canActivate],
        component: MainCompanyComponent,
        children: [
            {
                path: '',
                redirectTo: 'cockpit',
                pathMatch: 'full',
            },
            {
                path: 'users',
                canActivate: [canActivate],
                data: {
                    permissions: [
                        'client-user-add',
                        'client-user-remove',
                        'client-user-edit',
                        'client-user-read'
                    ],
                },
                loadChildren: () =>
                    import('./modules/cross-profile/users/users.module').then(
                        (mod) => mod.UsersModule
                    ),
            },
            // Disabled
            // {
            //     path: 'roles',
            //     canActivate: [canActivate],
            //     data: {
            //         permissions: [],
            //     },
            //     loadChildren: () =>
            //         import('./modules/cross-profile/roles/roles.module').then(
            //             (mod) => mod.RolesModule
            //         ),
            // },
            {
                path: 'approvals-configuration',
                canActivate: [canActivate],
                data: {
                    permissions: [],
                },
                loadChildren: () =>
                    import(
                        './modules/company/approvals-config/approvals-config.module'
                    ).then((mod) => mod.ApprovalsConfigModule),
            },
            {
                path: 'approvals-requests',
                canActivate: [canActivate],
                data: {
                    permissions: [],
                },
                loadChildren: () =>
                    import(
                        './modules/company/approvals-requests/approvals-requests.module'
                    ).then((mod) => mod.ApprovalsRequestsModule),
            },
            {
                path: 'approvals-history',
                canActivate: [canActivate],
                data: {
                    permissions: []
                },
                loadChildren: () => import('./modules/company/processing-history/processing-history.module')
                    .then(mod => mod.ProcessingHistoryModule)
            },
            {
                path: 'cockpit/company-master-data/base-data',
                canActivate: [canActivate],
                data: {
                    permissions: [
                        'admin#holder#bookkeeping',
                        'admin#standIn#bookkeeping',
                        'process#holder#edit-company-master-data',
                        'process#standIn#edit-company-master-data',
                        'confirm#holder#edit-company-master-data',
                        'confirm#standIn#edit-company-master-data',
                        'inform#holder#edit-company-master-data',
                        'inform#standIn#edit-company-master-data',
                    ],
                    viewPermission: 'view#edit-company-master-data',
                    forceRouting: true,
                },
                loadChildren: () =>
                    import(
                        './modules/cross-profile/companies/companies-form.module'
                    ).then((mod) => mod.CompaniesFormModule),
            },
            {
                path: 'optins',
                canActivate: [canActivate],
                data: {
                    permissions: [
                        'admin#holder#payroll-bookkeeping',
                        'admin#standIn#payroll-bookkeeping',
                        'process#holder#release-optin-to-consultancy',
                        'process#standIn#release-optin-to-consultancy',
                        'process#holder#deliver-employee-master-data-to-finstein',
                        'process#standIn#deliver-employee-master-data-to-finstein',
                        'process#holder#verify-employee-advice-requests',
                        'process#standIn#verify-employee-advice-requests',
                        'process#holder#change-employee-master-data',
                        'process#standIn#change-employee-master-data',
                        'inform#holder#change-employee-master-data',
                        'inform#standIn#change-employee-master-data',
                        'process#holder#sign-additions-to-employment-contracts',
                        'process#standIn#sign-additions-to-employment-contracts',
                        'inform#holder#sign-additions-to-employment-contracts',
                        'inform#standIn#sign-additions-to-employment-contracts',
                        'process#holder#release-employee-for-salary-increase',
                        'process#standIn#release-employee-for-salary-increase',
                        'inform#holder#release-employee-for-salary-increase',
                        'inform#standIn#release-employee-for-salary-increase',
                        'confirm#holder#release-employee-for-salary-increase',
                        'confirm#standIn#release-employee-for-salary-increase',
                    ],
                    viewPermission: [ 'view#change-employee-master-data',
                        'view#release-optin-to-consultancy',
                        'view#deliver-employee-master-data-to-finstein',
                        'view#register-optimized-gross-salary',
                        'view#sign-additions-to-employment-contracts']
                },
                loadChildren: () =>
                    import('./modules/company/optins/optins.module').then(
                        (mod) => mod.OptinsModule
                    ),
            },
            {
                path: 'cockpit',
                canActivate: [canActivate],
                // TODO add permissions
                loadChildren: () =>
                    import('./modules/company/cockpit/cockpit.module').then(
                        (mod) => mod.CockpitModule
                    ),
            },
            {
                path: 'cockpit/action-sequences',
                canActivate: [canActivate],
                data: {
                    permissions: [
                        'manage-admin-for-each-area',
                        'admin#holder#payroll-bookkeeping',
                        'admin#standIn#payroll-bookkeeping',
                        'admin#holder#bookkeeping',
                        'admin#standIn#bookkeeping',
                        'admin#holder#human-resource',
                        'admin#standIn#human-resource',
                    ],
                },
                loadChildren: () =>
                    import(
                        './modules/company/action-sequence/action-sequence.module'
                    ).then((mod) => mod.ActionSequenceModule),
            },
            {
                path: 'cockpit/company-master-data/services',
                canActivate: [canActivate],
                data: {
                    permissions: [
                        'manage-services-and-templates',
                        'manage-document-templates',
                        'manage-services',
                    ],
                },
                loadChildren: () =>
                    import(
                        './modules/company/benefits-services/benefits-services.module'
                    ).then((mod) => mod.BenefitsServicesModule),
            },
            {
                path: 'cockpit/rights',
                canActivate: [canActivate],
                data: {
                    onlyCompanyAdmin: true,
                },
                loadChildren: () =>
                    import(
                        './modules/company/inspect-rights/inspect-rights.module'
                    ).then((mod) => mod.InspectRightsModule),
            },
            {
                path: 'bookkeeping-suggestions',
                canActivate: [canActivate],
                loadChildren: () =>
                    import(
                        './modules/company/bookkeeping-suggestions/bookkeeping-suggestions.module'
                    ).then((mod) => mod.BookkeepingSuggestionsModule),
            },
            {
                path: 'settings',
                canActivate: [canActivate],
                // TODO add permissions
                loadChildren: () =>
                    import(
                        './modules/cross-profile/settings/settings.module'
                    ).then((mod) => mod.SettingsModule),
            },
            {
                path: 'employees',
                canActivate: [canActivate],
                data: {
                    permissions: [
                        'admin#holder#human-resource',
                        'admin#standIn#human-resource',
                        'process#holder#change-employee-master-data',
                        'process#standIn#change-employee-master-data',
                        'inform#holder#change-employee-master-data',
                        'inform#standIn#change-employee-master-data',
                        'process#holder#sign-additions-to-employment-contracts',
                        'process#standIn#sign-additions-to-employment-contracts',
                        'inform#holder#sign-additions-to-employment-contracts',
                        'inform#standIn#sign-additions-to-employment-contracts',
                        'process#holder#release-employee-for-salary-increase',
                        'process#standIn#release-employee-for-salary-increase',
                        'inform#holder#release-employee-for-salary-increase',
                        'inform#standIn#release-employee-for-salary-increase',
                        'confirm#holder#release-employee-for-salary-increase',
                        'confirm#standIn#release-employee-for-salary-increase',
                        'process#holder#register-optimized-gross-salary',
                        'process#standIn#register-optimized-gross-salary',
                        'inform#holder#register-optimized-gross-salary',
                        'inform#standIn#register-optimized-gross-salary',
                    ],
                    viewPermission: 'view#change-employee-master-data',
                },
                // TODO add permissions
                loadChildren: () =>
                    import(
                        './modules/cross-profile/employees/employees.module'
                    ).then((mod) => mod.EmployeesModule),
            },
            {
                path: 'video-library',
                data: {},
                canActivate: [canActivate],
                loadChildren: () =>
                    import(
                        './modules/company/video-library/video-library.module'
                    ).then((mod) => mod.VideoLibraryModule),
            },
            {
                path: 'hardware',
                data: {
                    permissions: [
                        'inform#holder#activation-of-hardware',
                        'inform#standIn#activation-of-hardware',
                    ],
                },
                canActivate: [canActivate],
                // TODO add permissions
                loadChildren: () =>
                    import('./modules/company/hardware/hardware.module').then(
                        (mod) => mod.HardwareModule
                    ),
            }
        ],
    },
    {
        path: 'administration',
        canActivate: [canActivate],
        data: {
            onlyFinsteinUsers: true,
        },
        component: MainAdministrationComponent,
        children: [
            {
                path: 'company-leads',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-company-lead-read', 'finstein-company-lead-read-external'],
                },
                loadChildren: () =>
                    import(
                        './modules/administration/company-leads/company-leads.module'
                    ).then((mod) => mod.CompanyLeadsModule),
            },
            {
                path: 'cell-phone-management',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-company-lead-read'],
                },
                loadChildren: () =>
                    import(
                        './modules/administration/cell-phone-plan-management/cell-phone-plan-management.module'
                    ).then((mod) => mod.CellPhonePlanManagementModule),
            },
            {
                path: 'companies',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-company-lead-read', 'finstein-company-lead-read-external'],
                },
                loadChildren: () =>
                    import(
                        './modules/cross-profile/companies/companies-form.module'
                    ).then((mod) => mod.CompaniesFormModule),
            },
            {
                path: 'contracts-tasks',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-role-read'],
                },
                loadChildren: () =>
                    import(
                        './modules/administration/contracts-tasks/contracts-tasks.module'
                    ).then((mod) => mod.ContractsTasksModule),
            },
            {
                path: 'users',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-user-read'],
                },
                loadChildren: () =>
                    import('./modules/cross-profile/users/users.module').then(
                        (mod) => mod.UsersModule
                    ),
            },
            // Disabled
            // {
            //     path: 'roles',
            //     canActivate: [canActivate],
            //     data: {
            //         permissions: ['finstein-role-read'],
            //     },
            //     loadChildren: () =>
            //         import('./modules/cross-profile/roles/roles.module').then(
            //             (mod) => mod.RolesModule
            //         ),
            // },
            {
                path: 'devices',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-role-read'],
                },
                // TODO add permissions
                loadChildren: () =>
                    import(
                        './modules/administration/companies-devices/companies-devices.module'
                    ).then((mod) => mod.CompaniesDevicesModule),
            },
            {
                path: 'support-requests',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-role-read'],
                },
                loadChildren: () =>
                    import(
                        './modules/administration/support-requests/support-requests.module'
                        ).then((mod) => mod.SupportRequestsModule),
            },
            {
                path: 'partners',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-role-read'],
                },
                // TODO add permissions
                loadChildren: () =>
                    import(
                        './modules/administration/partner-companies/partner-companies.module'
                    ).then((mod) => mod.PartnerCompaniesModule),
            },
            {
                path: 'employees',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-role-read'],
                },
                // TODO add permissions
                loadChildren: () =>
                    import(
                        './modules/cross-profile/employees/employees.module'
                    ).then((mod) => mod.EmployeesModule),
            },
            {
                path: 'services',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-role-read'],
                },
                // TODO add permissions
                loadChildren: () =>
                    import(
                        './modules/administration/company-services/company-services.module'
                    ).then((mod) => mod.CompanyServicesModule),
            },
            {
                path: 'bookkeeping-key',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-role-read'],
                },
                loadChildren: () =>
                    import(
                        './modules/administration/company-bookkeeping-key/company-bookkeeping-key.module'
                    ).then((mod) => mod.CompanyBookkeepingKeyModule),
            },
            // {
            //     path: 'audit',
            //     canActivate: [canActivate],
            //     data: {
            //         onlyAdmin: true,
            //         permissions: [
            //             'finstein-role-read'
            //         ]
            //     },
            //     loadChildren: () => import('./modules/administration/audit/audit.module').then(m => m.AuditModule)
            // },
            {
                path: 'update-company-contract-data',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-role-read'],
                },
                loadChildren: () =>
                    import(
                        './modules/administration/update-company-contract-data/update-company-contract-data.module'
                    ).then((m) => m.UpdateCompanyContractDataModule),
            },
            {
                path: 'variables',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-company-lead-read'],
                },
                loadChildren: () =>
                    import(
                        './modules/administration/global-variables/global-variables.module'
                    ).then((m) => m.GlobalVariablesModule),
            },
            {
                path: 'smartphone',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-company-lead-read' ],
                },
                loadChildren: () =>
                    import(
                        './modules/administration/smartphone-management/smartphone-management.module'
                    ).then((mod) => mod.SmartphoneManagementModule),
            },
            {
                path: 'emails-leads',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-company-lead-read', 'finstein-company-lead-read-external'],
                },
                loadChildren: () =>
                    import(
                        './modules/administration/emails-leads/emails-leads.module'
                    ).then((mod) => mod.EmailsLeadsModule),
            },
            {
                path: 'app-notifications',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-company-lead-read', 'finstein-company-lead-read-external'],
                },
                loadChildren: () =>
                    import(
                        './modules/administration/optimizer-notifications/optimizer-notifications.module'
                    ).then((mod) => mod.OptimizerNotificationsModule),
            },
            {
                path: 'download-center',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-role-read'],
                },
                loadChildren: () =>
                    import(
                        './modules/administration/download-center/download-center.module'
                    ).then((mod) => mod.DownloadCenterModule),
            },
            {
                path: 'finstein-helper',
                canActivate: [canActivate],
                data: {
                    onlyHelper: true,
                },
                loadChildren: () =>
                    import(
                        './modules/administration/finstein-helper/finstein-helper.module'
                    ).then((mod) => mod.FinsteinHelperModule),
            },
            {
                path: '',
                redirectTo: 'company-leads',
                pathMatch: 'full',
            },
            {
                path: 'receipts',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                    permissions: ['finstein-role-read'],
                },
                loadChildren: () =>
                    import(
                        './modules/cross-profile/employees/pages/review-receipts/review-receipts.module'
                    ).then((mod) => mod.ReviewReceiptsModule),
            },
            {
                path: 'consultancy-process',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                },
                loadChildren: () =>
                    import(
                        './modules/administration/cop-answers/cop-answers.module'
                    ).then((mod) => mod.CopAnswersModule),
            },
            {
                path: 'settings',
                canActivate: [canActivate],
                data: {
                    onlyFinsteinUsers: true,
                },
                loadChildren: () =>
                    import(
                        './modules/administration/settings/settings.module'
                    ).then((mod) => mod.SettingsModule),
            },
        ],
    },
    {
        path: 'settings',
        canActivate: [canActivate],
        component: SettingsComponent,
        loadChildren: () =>
            import('./modules/cross-profile/settings/settings.module').then(
                (m) => m.SettingsModule
            ),
    },
    {
        path: 'authentication',
        resolve: {
            translations: TranslationResolver
        },
        loadChildren: () =>
            import(
                './modules/cross-profile/authentication/authentication.module'
            ).then((m) => m.AuthenticationModule)
    },
    {
        path: 'imprint',
        resolve: {
            translations: TranslationResolver
        },
        loadChildren: () =>
            import(
                './modules/public/internal-pages/imprint/imprint.module'
                ).then((m) => m.ImprintModule)
    },
    {
        path: 'privacy',
        resolve: {
            translations: TranslationResolver
        },
        loadChildren: () =>
            import(
                './modules/public/internal-pages/privacy/privacy.module'
                ).then((m) => m.PrivacyModule)
    },
    {
        path: 'notification-settings',
        resolve: {
            translations: TranslationResolver
        },
        loadChildren: () =>
            import(
                './modules/public/email-notification-management/email-notification-management.module'
                ).then((m) => m.EmailNotificationManagement)
    },
    {
        path: 'validation',
        loadChildren: () =>
            import(
                './modules/company/company-responsible-validator/company-responsible-validator.module'
            ).then((m) => m.CompanyResponsibleValidatorModule),
    },
    { path: '**', redirectTo: 'authentication', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
