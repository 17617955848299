import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { EMPTY } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AuthFirebaseService } from 'src/app/modules/cross-profile/authentication/auth-firebase.service';
import { SystemWarningsNotificationComponent } from './system-warnings-notification/system-warnings-notification.component';
import { MatDialog } from '@angular/material/dialog';
import { name, version } from './package-info';
import { compareVersions } from 'src/app/utils/compare-version.util';

export interface SystemWarningData {
    minimalVersion: string;
    underMaintenance: boolean;
    forceRedirectToMainPage: boolean;
}

@Injectable()
export class SystemWarningsService {
    constructor(
        private db: AngularFireDatabase,
        private dialog: MatDialog,
        private authService: AuthFirebaseService
    ) {}

    init() {
        this.db
            .object('portal/deploy-settings')
            .valueChanges()
            .pipe(
                concatMap((value: SystemWarningData) => {
                    if (this.isRequiredToRefreshPage(value)) {
                        return this.dialog
                            .open(SystemWarningsNotificationComponent, {
                                id: `${name}_${version}`,
                                panelClass: 'curved-modal',
                                disableClose: true,
                                data: {
                                    title: 'system-warnings-modal.check-version.title',
                                    description:
                                        'system-warnings-modal.check-version.description',
                                    withRefresh: !value.forceRedirectToMainPage,
                                    afterConfirmFn: () => {
                                        if (value.forceRedirectToMainPage) {
                                            this.authService
                                                .signOut()
                                                .subscribe(() => {
                                                    location.replace(
                                                        '/authentication'
                                                    );
                                                });
                                        } else {
                                            location.reload();
                                        }
                                    },
                                },
                                maxWidth: '50vw',
                            })
                            .afterOpened();
                    } else if (value.underMaintenance) {
                        return this.dialog
                            .open(SystemWarningsNotificationComponent, {
                                id: `${name}_${version}`,
                                panelClass: 'curved-modal',
                                data: {
                                    title: 'system-warnings-modal.under-maintenance.title',
                                    description:
                                        'system-warnings-modal.under-maintenance.description',
                                    afterConfirmFn: () => {
                                        if (value.forceRedirectToMainPage) {
                                            this.authService
                                                .signOut()
                                                .subscribe(() => {
                                                    location.replace(
                                                        '/authentication'
                                                    );
                                                });
                                        }
                                    },
                                },
                                disableClose: !value.forceRedirectToMainPage,
                            })
                            .afterOpened();
                    }
                    return EMPTY;
                })
            )
            .subscribe();
    }

    private isRequiredToRefreshPage({ minimalVersion }) {
        return compareVersions(version, minimalVersion) == -1;
    }
}
