import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { LANG_KEY_LOCAL_STORAGE_KEY } from './app.constants';
import { AppService } from './core/services/app.service';
import { StorageService } from './core/services/storage.service';
import { CookieDialogComponent } from './shared/components/cookie-dialog/cookie-dialog.component';
import { CookieTypesEnum } from './shared/enums/cookie-types.enum';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {

    private delayToOpenCookiesModal = 500;
    private defaulLanguage = 'de';

    constructor(
        private translate: TranslateService,
        private storageService: StorageService,
        private appService: AppService,
        private ngSelectConfig: NgSelectConfig,
        private dialog: MatDialog
    ) {
    }

    async ngOnInit() {
        this.configTranslate();
        this.configSelectTranslate();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            window.document.getElementById('finstien-loading')?.remove();
            if (!this.appService.getCookiesAllowed()) {
                this.dialog.open(CookieDialogComponent, {
                    width: '650px',
                    height: 'auto',
                    maxHeight: '95vh',
                    data: {
                        cookieTypes: [CookieTypesEnum.NECESSARY,
                            CookieTypesEnum.PREFERENCES]
                    }
                });
            }
        }, this.delayToOpenCookiesModal);
    }

    private configTranslate() {
        let cookieLang = this.storageService.get(LANG_KEY_LOCAL_STORAGE_KEY);
        if (!cookieLang) {
            if (this.appService.isCookieIsAllowed(CookieTypesEnum.PREFERENCES)) {
                this.storageService.save(LANG_KEY_LOCAL_STORAGE_KEY, this.defaulLanguage);
            }
            cookieLang = this.defaulLanguage;
        }
        this.translate.use(cookieLang.match(/en|de/) ? cookieLang : this.defaulLanguage);
    }

    private configSelectTranslate() {
        timer(500).subscribe(() => {
            this.translate.get('system.empty-list').subscribe((result: string) => {
                this.ngSelectConfig.notFoundText = result;
            });
            this.translate.get('system.type-to-search').subscribe((result: string) => {
                this.ngSelectConfig.typeToSearchText = result;
            });
        });
    }

}
