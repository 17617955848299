import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationBellComponent} from './notification-bell.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [NotificationBellComponent],
    exports: [NotificationBellComponent]

})
export class NotificationBellModule {
}
