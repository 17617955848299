/* src/app/core/components/header/header.component.scss */
.badge {
  line-height: 1;
}
.user-name {
  max-width: 300px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-container {
  padding: 2% 0;
}
.notifications .icon {
  margin-top: 4px !important;
}
.notifications .dropdown-menu {
  width: 22rem !important;
  right: 0 !important;
  max-height: 32rem !important;
  overflow-y: hidden !important;
  left: inherit !important;
  padding-top: 10px !important;
}
.custom-badge {
  position: relative;
  left: 28px !important;
  font-size: 74% !important;
  bottom: 13px !important;
  border-radius: 50% !important;
  top: -5px;
}
.not-viewed {
  background-color: #c0c6c7;
}
.notifications .dropdown-toggle::after {
  display: none !important;
}
.notification {
  padding-top: 13px;
  padding-bottom: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: center;
  border-top: 1px solid #d6d6d6;
}
.notification p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.notification .time {
  text-align: end;
  font-size: 12px;
  color: grey;
}
.notification .title {
  text-align: start;
}
.no-padding {
  padding: 0;
}
.header {
  display: inline-flex;
  float: right;
}
.user-info {
  display: inline-flex;
}
.user-info .user-image {
  background: #5B6D80;
  border-radius: 50%;
  padding: 4px 8px 10px;
  margin-right: 8px;
}
.dropdown-toggle:empty::after {
  margin-left: 13px !important;
}
.d-inline-block {
  cursor: pointer;
}
.user-info span {
  color: #495057;
}
.dropdown-menu.show {
  padding: 19px;
  max-width: 22rem;
}
.dropdown-menu {
  min-width: 16rem !important;
}
.language-label {
  font-size: 1rem;
  margin-right: 12px;
}
.logout-label {
  font-size: 13px;
  padding-top: 0 !important;
  margin-top: 1px;
  margin-left: 6px;
}
.fa-sign-out {
  font-size: 22px !important;
  color: #657381;
}
.form-control {
  width: 59% !important;
  display: inline-flex !important;
  font-size: 13px;
}
.dropdown-toggle::after {
  margin-left: 0.9em !important;
  margin-top: 8px !important;
}
.padding {
  padding-left: 15px;
  padding-right: 15px;
}
.btn-logout {
  padding-top: 0;
  padding-bottom: 0;
}
/*# sourceMappingURL=header.component-EQ7EDV4Z.css.map */
