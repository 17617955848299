import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { VersionTagModule } from 'src/app/shared/components/version-tag/version-tag.module';
import { HttpLoaderFactory } from 'src/app/utils/translator.util';
import { NotificationBellModule } from '../../../shared/components/notification-bell/notification-bell.module';
import { SharedModule } from '../../../shared/shared.module';
import { CompanySideMenuComponent } from './company-side-menu.component';
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        SharedModule,
        RouterModule,
        NgxPermissionsModule.forChild(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NotificationBellModule,
        VersionTagModule,
        MatTooltipModule,
    ],
    declarations: [
        CompanySideMenuComponent
    ],
    exports: [
        CompanySideMenuComponent
    ]
})
export class CompanySideMenuModule {
}
