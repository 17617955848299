// translation.resolver.ts
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DEFAULT_LANGUAGE } from './app.constants';

@Injectable({
    providedIn: 'root'
})
export class TranslationResolver  {
    constructor(private translateService: TranslateService) { }

    resolve(): Observable<any> {
        return this.translateService.getTranslation(DEFAULT_LANGUAGE).pipe(take(1));
    }
}
