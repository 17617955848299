<finstein-base-modal [disableClose]="true" [title]="data.title" bodyClass="none">
    <finstein-circle-warn header></finstein-circle-warn>
    <finstein-plain-text-content
        content
        [text]="data.description"
    ></finstein-plain-text-content>

    <ng-container *ngIf="data.withRefresh; else onlyConfirm" actions>
        <div class="d-flex flex-column align-items-center text-center" style="width: 60%">
            <button
                class="btn btn-primary mb-0"
                (click)="confirm()"
                translate
            >
                system-warnings-modal.buttons.refresh
            </button>
            <a class="btn mb-2 mt-1" translate (click)="close()">
                system-warnings-modal.buttons.postpone
            </a>
        </div>
    </ng-container>
    <ng-template #onlyConfirm>
        <finstein-action-confirm
            type="done"
            doneBtnTitle="system.confirm"
            (doneClick)="confirm()"
        >
        </finstein-action-confirm>
    </ng-template>
</finstein-base-modal>
