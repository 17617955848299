<div [id]="target" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title col-sm-11 no-padding" translate>core.header.notification-details</h3>
                <button id='btn-close' type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <p class="text-center font-weight-bold notification-title">{{notification?.title}}</p>
                <p class="p-4">{{notification?.body}}</p>
                <p class="notification-time">{{getNotificationTime()}}</p>
            </div>
        </div>
    </div>
</div>
