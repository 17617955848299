import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../../shared/shared.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../../utils/translator.util';
import {NotificationDetailsModule} from '../notification-details/notification-details.module';
import {SettingsModule} from '../../../modules/cross-profile/settings/settings.module';
import {EllipsifyModule} from '../../directives/ellipsify/ellipsify.module';
import {MatRippleModule} from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        SharedModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatDialogModule,
        SettingsModule,
        NotificationDetailsModule,
        EllipsifyModule,
        RouterModule,
        MatRippleModule
    ],
    declarations: [HeaderComponent],
    exports: [HeaderComponent]
})
export class HeaderModule {
}
